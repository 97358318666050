import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/Layouts/layout'
import SEO from '../../components/seo'
import PersonRow from '../../components/PersonRow/PersonRow'

import Virginia from '../../imageComponents/virginiaW'
import Hugh from '../../imageComponents/hughH'
import Mary from '../../imageComponents/maryKB'
import Av from '../../imageComponents/avMaharaj'
import Marg from '../../imageComponents/margR'
import Terence from '../../imageComponents/terenceYoung'
import Rinku from '../../imageComponents/rinkuDeswal'
import Sam from '../../imageComponents/samBiasucci'

const BoardPage = () => {
  const intl = useIntl()

  return (
    <Layout
      breadcrumb={{
        text: <FormattedMessage id='nav.about' />,
        path: '/about/'
      }}
      header={<FormattedMessage id='nav.board' />}
    >
      <SEO
        description={intl.formatMessage({ id: 'metaTags.aboutDescription' })}
        lang={intl.locale}
        title={intl.formatMessage({ id: 'nav.board' })}
      />
      <Container>
        <Row>
          <Col>
            <p>
              <FormattedMessage id='board.content01' />
              <br />
              <br />
              <FormattedMessage id='board.content02' />
            </p>
          </Col>
        </Row>

        <PersonRow image={<Marg />}>
          <h3 className='underline'>
            <span class='sm uppercase'>
              <FormattedMessage id='board.chair' />
            </span>
            <br />
            <span class='thin'>Marg</span> Rappolt
          </h3>
          <p>
            <FormattedMessage id='board.marg' />
          </p>
        </PersonRow>

        <PersonRow image={<Av />}>
          <h3 className='underline'>
            <span class='sm uppercase'>
              <FormattedMessage id='board.viceChair' />
            </span>
            <br />
            <span class='thin'>Av</span> Maharaj
          </h3>
          <p>
            <FormattedMessage id='board.av' />
          </p>
        </PersonRow>

        <PersonRow image={<Sam />}>
          <h3 className='underline'>
            <span class='thin'>Salvatore “Sam”</span> Biasucci
          </h3>
          <p>
            <FormattedMessage id='board.sam' />
          </p>
        </PersonRow>

        <PersonRow image={<Rinku />}>
          <h3 className='underline'>
            <span class='thin'>Rinku</span> Deswal
          </h3>
          <p>
            <FormattedMessage id='board.rinku' />
          </p>
        </PersonRow>

        <PersonRow image={<Hugh />}>
          <h3 className='underline'>
            <span class='thin'>Hugh</span> Heron
          </h3>
          <p>
            <FormattedMessage id='board.hugh' />
          </p>
        </PersonRow>

        <PersonRow image={<Mary />}>
          <h3 className='underline'>
            <span class='thin'>Mary</span> Kardos Burton
          </h3>
          <p>
            <FormattedMessage id='board.mary' />
          </p>
        </PersonRow>

        <PersonRow image={<Virginia />}>
          <h3 className='underline'>
            <span class='thin'>Virginia</span> West
          </h3>
          <p>
            <FormattedMessage id='board.virginia' />
          </p>
        </PersonRow>

        <PersonRow image={<Terence />}>
          <h3 className='underline'>
            <span class='thin'>Terence</span> Young
          </h3>
          <p>
            <FormattedMessage id='board.terence' />
          </p>
        </PersonRow>
      </Container>
    </Layout>
  )
}

export default BoardPage
