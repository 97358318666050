import React from 'react'
import ClassNames from 'classnames'
import componentStyles from './PersonRow.module.scss'

const PersonRow = ({ image, direction, size, ...props }) => {
  var styles
  if (direction === 'vertical')
    styles = ClassNames(componentStyles.row, componentStyles.col)
  else if (size === 'large')
    styles = ClassNames(componentStyles.row, componentStyles.large)
  else styles = componentStyles.row

  return (
    <div className={styles}>
      {image}
      <div className={componentStyles.content}>{props.children}</div>
    </div>
  )
}

export default PersonRow
